<template>
  <div>
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-0"
      :header-props="{ 'sort-icon': 'bi bi-caret-up-fill' }"
      :sort-by="sortable !== undefined ? ['updated_at'] : ''"
      :sort-desc="[true]"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading..."
      no-data-text="Nenhuma Informação encontrada"
      no-results-text="Nenhuma Informação encontrada"
    >
      <template v-slot:item.last_sync="{ item }">
        <span> {{ item.last_sync | formatDate }}</span>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <span> {{ item.updated_at | formatDate }}</span>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span v-if="item.created_at"> {{ item.created_at | formatDate }}</span>
        <span v-else>00/00/0000</span>
      </template>

      <template v-slot:item.created_details="{ item }">
        <span v-if="item.created_at"> {{ item.created_at | formatDate }}</span>
        <span v-else>00/00/0000</span>
      </template>

      <template v-slot:item.size="{ item }">
        <span> {{ item.size | formatBytes }}</span>
      </template>

      <template v-slot:item.user="{ item }">
        <span> {{ item.user ? item.user.name : "-" }}</span>
      </template>
      <template v-slot:item.updated="{ item }">
        <span> {{ item.updated_at | formatDatePay }}</span>
      </template>

      <template v-slot:item.userEmail="{ item }">
        <span> {{ item.user ? item.user.email || "-" : "-" }} </span>
      </template>

      <template v-slot:item.user_action="{ item }">
        <span v-if="item.action"> {{ item.action }} </span>
      </template>
      <template v-slot:item.description="{ item }">
        <span class="medium-text">
          {{ item.description ? item.description : "-" }}
        </span>
      </template>

      <template v-slot:item.axis_id="{ item }">
        <span>{{ getName(item) }} </span>
      </template>

      <template v-slot:item.action="{ item }">
        <i
          @click="ActionButton(item)"
          class="bi bi-arrow-up-right-circle icon"
        ></i>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  components: {},
  name: "simpletable",
  props: {
    items: {
      type: [Array, Object, String],
      require: true,
    },
    axis: {
      type: [Array],
    },
    sortable: {
      type: [Array, String],
    },
    headers: {
      type: [Array],
      require: true,
    },
    footer: {
      type: Boolean,
      require: false,
    },
    search: {
      type: [String, Number],
      require: true,
    },
    loading: {
      type: Boolean,
    },

    settings: Object,
  },

  data() {
    return {};
  },

  methods: {
    getName(i) {
      let name = "";
      this.axis.forEach((e) => {
        if (e.id === i.axis_id) {
          name = e.name;
        }
      });
      return name;
    },
    ActionButton(e) {
      this.$emit("ActionBtn", e);
    },
  },
};
</script>
<style scoped src="@/assets/css/simpletable.css"></style>
