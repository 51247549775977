var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"header-props":{ 'sort-icon': 'bi bi-caret-up-fill' },"sort-by":_vm.sortable !== undefined ? ['updated_at'] : '',"sort-desc":[true],"hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"loading-text":"Loading...","no-data-text":"Nenhuma Informação encontrada","no-results-text":"Nenhuma Informação encontrada"},scopedSlots:_vm._u([{key:"item.last_sync",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_sync)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updated_at)))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at)))]):_c('span',[_vm._v("00/00/0000")])]}},{key:"item.created_details",fn:function(ref){
var item = ref.item;
return [(item.created_at)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at)))]):_c('span',[_vm._v("00/00/0000")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatBytes")(item.size)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.user ? item.user.name : "-"))])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDatePay")(item.updated_at)))])]}},{key:"item.userEmail",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.user ? item.user.email || "-" : "-")+" ")])]}},{key:"item.user_action",fn:function(ref){
var item = ref.item;
return [(item.action)?_c('span',[_vm._v(" "+_vm._s(item.action)+" ")]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"medium-text"},[_vm._v(" "+_vm._s(item.description ? item.description : "-")+" ")])]}},{key:"item.axis_id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getName(item))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('i',{staticClass:"bi bi-arrow-up-right-circle icon",on:{"click":function($event){return _vm.ActionButton(item)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }